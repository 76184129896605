@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    li {
        @apply p-4
    }

    button {
        @apply text-white border bg-[#008080] border-slate-200 
        hover:bg-indigo-600/20 hover:text-indigo-600 rounded-md
    }

    body {
      @apply bg-stone-300/20
  }
}
